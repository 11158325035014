@charset "UTF-8";
/*
x FoundersGrotCondWeb-Bold.eot
x FoundersGrotCondWeb-Bold.woff
x FoundersGrotCondWeb-Regular.eot
x FoundersGrotCondWeb-Regular.woff
x FoundersGrotCondWeb-Semibold.eot
x FoundersGrotCondWeb-Semibold.woff
x FoundersGroteskWeb-Light.eot
x FoundersGroteskWeb-Light.woff
TiemposTextWebLF-Regular.eot	(Fonts with "LF" appended have lining figures as default)
TiemposTextWebLF-RegularItalic.eot
TiemposTextWebLF-RegularItalic.woff
TiemposTextWebLF-Regular.woff
x TiemposTextWeb-Regular.eot
x TiemposTextWeb-Regular.woff
x TiemposTextWeb-RegularItalic.eot
x TiemposTextWeb-RegularItalic.woff
*/
/* Founders Grotesk */
@font-face {
  /* Regular */
  font-family: "founders-grotesk";
  src: url(../fonts/FoundersGrotCondWeb-Regular.eot);
  src: url(../fonts/FoundersGrotCondWeb-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  /* Bold - use font-weight:bold; */
  font-family: "founders-grotesk";
  src: url(../fonts/FoundersGrotCondWeb-Bold.eot);
  src: url(../fonts/FoundersGrotCondWeb-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  /* Semi - use font-weight:600; */
  font-family: "founders-grotesk";
  src: url(../fonts/FoundersGrotCondWeb-Semibold.eot);
  src: url(../fonts/FoundersGrotCondWeb-Semibold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  /* Light - use font-weight:200; */
  font-family: "founders-grotesk";
  src: url(../fonts/FoundersGroteskWeb-Light.eot);
  src: url(../fonts/FoundersGroteskWeb-Light.woff) format("woff");
  font-weight: 200;
  font-style: normal;
}
/* Tiempos Text */
@font-face {
  font-family: "tiempos-text";
  src: url(../fonts/TiemposTextWeb-Regular.eot);
  src: url(../fonts/TiemposTextWeb-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "tiempos-text";
  src: url(../fonts/TiemposTextWeb-RegularItalic.eot);
  src: url(../fonts/TiemposTextWeb-RegularItalic.woff) format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "tiempos-text";
  src: url(../fonts/TiemposTextWeb-Semibold.eot);
  src: url(../fonts/TiemposTextWeb-Semibold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "tiempos-text";
  src: url(../fonts/TiemposTextWeb-SemiboldItalic.eot);
  src: url(../fonts/TiemposTextWeb-SemiboldItalic.woff) format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?rqhn2s");
  src: url("../fonts/icomoon.eot?rqhn2s#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?rqhn2s") format("truetype"), url("../fonts/icomoon.woff?rqhn2s") format("woff"), url("../fonts/icomoon.svg?rqhn2s#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e900";
}

.icon-help:before {
  content: "\e901";
}

.icon-study:before {
  content: "\e902";
}

.icon-checkform:before {
  content: "\e903";
}

html {
  box-sizing: border-box;
}

body {
  font-family: tiempos-text;
}
body.has-header {
  padding-top: 71px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, #header {
  margin: auto;
  max-width: 1000px;
}

pre {
  position: relative;
  border: 1px dashed #ff0000;
  background-color: #fff;
  padding: 7px;
  font-family: Arial, sans-serif;
  font-size: 15px;
  line-height: 1.3;
  color: #000;
  white-space: pre-wrap;
}

a {
  color: #70257a;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 20px;
}

h1 {
  color: #70257a;
  font: 42px/55px founders-grotesk, "Arial", sans-serif;
}
h1.headline {
  font-weight: bold;
  color: black;
  margin: 0 0 10px 0;
  vertical-align: baseline;
  line-height: 39px;
}

h2 {
  font-family: founders-grotesk;
}

blockquote {
  display: block;
  margin: 0;
  padding-left: 30px;
}

dl {
  font: 20px/1 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 25px;
}
dl dt {
  font-weight: 600;
}
dl dt:nth-child(n+2) {
  margin-top: 10px;
}
dl dd {
  margin: 0;
  padding: 0;
}

.centered {
  text-align: center !important;
}

.raisecaps {
  float: left;
  font: 25px/15px founders-grotesk, "Arial", sans-serif;
  font-weight: bold;
  padding: 0 4px 0 0;
}

.raisecaps-heading {
  line-height: 1.3;
}

.innerquote {
  color: #70257a;
  display: block;
  font: 600 25px/1.1 founders-grotesk, "Arial", sans-serif;
  padding: 0 10px 20px 25px;
}

.dropcap {
  float: left;
  font: 49px/36px founders-grotesk, "Arial", sans-serif;
  padding: 3px 4px 0px 0px;
  margin-bottom: -3px;
  font-weight: 600;
}

/*
.meta.ng-binding {
  display: none;
}
*/
.container:after {
  content: "";
  display: table;
  clear: both;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
}

.auth-container {
  position: relative;
}

.fp-box {
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.75);
}

.fp-block {
  padding: 30px 0;
}
.fp-block.first {
  padding-top: 10px;
}
.fp-block.first .meta.ng-binding {
  font-family: founders-grotesk, "Arial", sans-serif;
}
.fp-block.shaded {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0) 20px);
}
.fp-block.shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
  position: relative;
}
.fp-block.full-width {
  margin-left: -20px;
  margin-right: -20px;
}
.fp-block.loading {
  background: url(../img/loading_purple.gif) no-repeat center;
  padding: 0;
}

.fp_featured_column_wrapper {
  position: relative;
  border: 1px solid #1a709e;
  margin-bottom: 20px;
}
.fp_featured_column_wrapper img.responsive {
  width: 100%;
}
.fp_featured_column_wrapper .column_meta {
  padding: 10px;
}
.fp_featured_column_wrapper .column_meta h2 {
  font: 40px/42px founders-grotesk, "Arial", sans-serif;
  line-height: 0.85;
  margin: 5px 0 15px 0;
  padding: 0;
  color: #000;
  font-weight: 600;
  text-align: center;
}
.fp_featured_column_wrapper .column_meta span.category {
  font: 18px/1 founders-grotesk, "Arial", sans-serif;
  display: block;
  color: #1a709e;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}
.fp_featured_column_wrapper .column_meta .excerpt {
  text-align: center;
}
.fp_featured_column_wrapper .column_meta .author_flexbox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.fp_featured_column_wrapper .column_meta .author_flexbox .author_image {
  margin: 5px 10px;
}
.fp_featured_column_wrapper .column_meta .author_flexbox .author_image .bio_image_wrapper {
  border-radius: 50%;
  height: 70px;
  width: 70px;
  overflow: hidden;
}
.fp_featured_column_wrapper .column_meta .author_flexbox .author_name {
  text-transform: uppercase;
  font: 17px/1 founders-grotesk, "Arial", sans-serif;
  font-weight: 200;
}
.fp_featured_column_wrapper .column_meta .author_flexbox .author_name a {
  color: #000;
}

.featured-block {
  border-top: 1px solid #a37b36;
  border-bottom: 1px solid #a37b36;
  margin-bottom: 20px;
  padding: 20px 0;
}
.featured-block h2 {
  color: #a37b36;
  font: 600 18px/1 founders-grotesk, "Arial", sans-serif;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  text-align: center;
}
.featured-block .block-content {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.featured-block .block-content .left, .featured-block .block-content .right {
  display: table-cell;
  padding: 0 10px;
  vertical-align: top;
}
.featured-block .block-content .right {
  color: #000000;
  font: 700 28px/1 founders-grotesk, "Arial", sans-serif;
  vertical-align: middle;
}

.subscribe-block {
  background: #70257a;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 15px 25px;
}
.subscribe-block h2 {
  font: 600 24px/1 founders-grotesk;
  margin: 0 0 0 0;
}
.subscribe-block p {
  font: 500 24px/1 founders-grotesk;
  margin: 0 0 10px 0;
}
.subscribe-block .input-append input {
  width: calc(100% - 100px);
}
.subscribe-block .input-append button {
  color: #70257a;
  text-align: right;
  width: 100px;
}
.subscribe-block .checkboxes {
  text-align: left;
}

.subscribe-block.alternate {
  background: transparent;
  border-top: 1px solid #c38dbe;
  border-bottom: 1px solid #c38dbe;
}
.subscribe-block.alternate h2, .subscribe-block.alternate p {
  color: #010101;
}
.subscribe-block.alternate .input-append {
  background: #e1d4e8;
}
.subscribe-block.alternate .input-append input {
  color: #ffffff;
}
.subscribe-block.alternate .input-append input::-moz-placeholder {
  color: #ffffff;
}
.subscribe-block.alternate .input-append input:-ms-input-placeholder {
  color: #ffffff;
}
.subscribe-block.alternate .input-append input::-webkit-input-placeholder {
  color: #ffffff;
}
.subscribe-block.alternate .input-append button {
  color: #010002;
}
.subscribe-block.alternate .checkboxes .checkbox {
  color: #010002;
}
.subscribe-block.alternate .checkboxes .checkbox input[type=checkbox]:checked + span {
  background: #e1d4e8 url(../img/checkbox.png) no-repeat center;
}
.subscribe-block.alternate .checkboxes .checkbox span {
  background: #e1d4e8;
}

.observation-block h2 {
  color: #000000;
  font: bold 42px/55px founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
  vertical-align: baseline;
  line-height: 39px;
}
.observation-block p {
  font: 22px/1.1 founders-grotesk, "Times New Roman", serif;
}
.observation-block .meta {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.observation-block .meta .author, .observation-block .meta .date {
  display: table-cell;
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.observation-block .meta .author {
  text-align: left;
}
.observation-block .meta .date {
  text-align: right;
}

.pick-block h2 {
  color: #000000;
  font: bold 42px/55px founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
  vertical-align: baseline;
  line-height: 39px;
}
.pick-block p {
  font: 22px/1.1 founders-grotesk, "Times New Roman", serif;
}

.readmore {
  font: 600 26px/1 founders-grotesk, "Arial", sans-serif;
  letter-spacing: 1px;
}

.popular-block {
  border-top: 1px solid #000000;
  padding: 20px 0 0 0;
}
.popular-block h1 {
  color: #70257a;
  font: 600 18px/1 founders-grotesk, "Arial", sans-serif;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  text-align: center;
}
.popular-block .item {
  border-top: 1px solid #000000;
  padding: 20px 0 0 0;
}
.popular-block .item h2 {
  color: #000000;
  font: bold 42px/55px founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
  vertical-align: baseline;
  line-height: 39px;
}
.popular-block .item .essay-header {
  margin-bottom: 30px;
}
.popular-block .item .meta {
  margin-bottom: 20px;
}
.popular-block .item .meta span.type {
  color: #70257a;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.popular-block .item .meta span.author {
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.popular-block .item .meta span.type + span.author {
  margin-left: 20px;
}
.popular-block .item.essay {
  border-top-color: #70257a;
}
.popular-block .item.observation {
  border-top-color: #a37b36;
}
.popular-block .item.observation .meta span.type {
  color: #a37b36;
}

.tags a + a:before {
  content: ", ";
  color: #000000;
}

.archive-block.author-block h2 {
  color: #000000;
  margin-top: 0;
  margin-bottom: 4px;
}
.archive-block.author-block + .archive-block.author-block {
  border-top: 1px solid #e2e5e6;
}
.archive-block.book-block {
  display: table;
  table-layout: fixed;
}
.archive-block.book-block .left, .archive-block.book-block .right {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
.archive-block.book-block .left {
  padding-right: 10px;
}
.archive-block.book-block .right {
  padding-left: 10px;
}
.archive-block.book-block .right h2 {
  font: bold 26px founders-grotesk, "Arial", sans-serif;
  margin: 0;
}
.archive-block.book-block .right p {
  font: 26px founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
}
.archive-block.book-block .right a {
  font-size: 24px;
}

.archive-title {
  border-bottom: 1px solid #e2e5e6;
  color: #506168;
  font: 42px/55px founders-grotesk, "Arial", sans-serif;
  margin-bottom: 20px;
  padding-bottom: 30px;
  text-align: center;
}
.archive-title.authors-title {
  margin-bottom: 0;
}

.post-header {
  margin-bottom: 10px;
  text-align: center;
}
.post-header .issue-meta {
  font: 700 17px founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 5px;
}
.post-header .issue-meta .title {
  color: #70257a;
}
.post-header .image-holder {
  margin-bottom: 10px;
  position: relative;
}
.post-header .image-holder img {
  vertical-align: middle;
}
.post-header .image-holder .caption-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.post-header .image-holder .caption-holder .toggle {
  display: block;
  background: url(../img/caption-toggle.png) no-repeat;
  width: 33px;
  height: 33px;
  position: absolute;
  left: 10px;
  bottom: 10px;
}
.post-header .image-holder .caption-holder .caption {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  color: #ffffff;
  padding: 10px;
}
.post-header .image-holder .caption-holder .caption p {
  margin: 0;
}
.post-header .image-holder .caption-holder.toggled .toggle {
  display: none;
}
.post-header .image-holder .caption-holder.toggled .caption {
  display: block;
}
.post-header h1 {
  font: bold 42px/55px founders-grotesk, "Arial", sans-serif;
  color: black;
  margin: 0 0 10px 0;
  vertical-align: baseline;
  line-height: 39px;
}
.post-header p {
  font: 22px/1.1 founders-grotesk, "Times New Roman", serif;
}
.post-header .meta {
  display: table;
  margin: 0 0 20px 0;
  table-layout: fixed;
  text-align: left;
  width: 100%;
}
.post-header .meta .column {
  display: table-cell;
}
.post-header .meta .column span.type {
  display: block;
  color: #70257a;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.post-header .meta .column span.type strong {
  font-weight: inherit;
  color: #1a709e;
}
.post-header .meta .column span.author {
  display: block;
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.post-header .meta .column span.author a {
  color: #000000;
}
.post-header .meta .column span.date {
  display: block;
  color: #000000;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.post-header .essay_share {
  text-align: center;
  padding: 10px 0;
}
.post-header .essay_share a.share_article {
  background: transparent url(../img/essay_social_icons.png) no-repeat;
  height: 21px;
  width: 21px;
  display: inline-block;
}
.post-header .essay_share a.share_article.share_article_twitter {
  background-position: 0 0;
  padding-right: 10%;
}
.post-header .essay_share a.share_article.share_article_facebook {
  padding-right: 10%;
  background-position: 0 -74px;
}
.post-header .essay_share a.share_article.share_article_email {
  background: transparent url(../img/mosaic_sb_control_icons.png) no-repeat;
  background-position: 0px -86px;
  width: 26px;
}

.post-header.observation .meta span.type {
  color: #a37b36;
}
.post-header.observation .meta span.type.column {
  color: #1a709e;
}
.post-header.observation .issue-meta .title {
  color: #a37b36;
}

.post-header.post-header-subscribe {
  background-color: #e9b646;
  margin-bottom: 1px;
  margin-top: 25px;
  padding: 20px 20px;
}
.post-header.post-header-subscribe h1 {
  font-weight: 400;
  color: #70257a;
}
.post-header.post-header-subscribe p {
  margin-bottom: 0;
}

.in-content-block {
  margin: 25px 0;
}

.essay-content {
  font: 16px/22px tiempos-text, "Times New Roman", serif;
}
.essay-content img {
  width: 100%;
  height: auto;
}
.essay-content iframe {
  width: 100% !important;
}
.essay-content .wp-caption {
  width: 100% !important;
}
.essay-content .paywall-excerpt {
  pointer-events: none;
  position: relative;
}
.essay-content .paywall-excerpt::after {
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgb(255, 255, 255) 99%, rgb(255, 255, 255) 100%);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.essay-content .author-block {
  background: #f0e8f3;
  font: 18px/1.2 founders-grotesk, "Arial", sans-serif;
  padding: 10px;
  text-align: center;
  margin-bottom: 15px;
}
.essay-content .author-block h2 {
  font: 600 18px/1 founders-grotesk, "Arial", sans-serif;
  margin: 0;
  text-transform: uppercase;
}
.essay-content .author-block p {
  margin-bottom: 0px;
  line-height: 1.15;
}
.essay-content .author-block p:not(:last-child) {
  margin: 0 0 10px 0;
  border-bottom: 1px solid white;
  padding-bottom: 10px;
}
.essay-content .author-block a {
  color: #70257a;
}
.essay-content .author-block.columnist {
  background: none;
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}
.essay-content .author-block.columnist h2 {
  text-align: center;
  text-transform: none;
  font-size: 25px;
  margin: 0 0 5px 0;
}
.essay-content .author-block.columnist h2 a {
  color: #000;
}
.essay-content .author-block.columnist .bio_image_wrapper {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #f3f3f3;
  overflow: hidden;
  margin: 15px auto;
}
.essay-content .author-block.columnist .block-sponsor {
  font-weight: 600;
  border-top: 1px solid #666;
  padding-top: 15px;
  margin-top: 15px;
}
.essay-content .author-block.columnist .block-sponsor p {
  padding-bottom: 5px;
}
.essay-content .fp-block {
  margin-bottom: 30px;
}
.essay-content .post-divider {
  border-bottom: 2px solid #70257a;
  margin-bottom: 30px;
}

.books-header {
  padding-top: 30px;
  text-align: center;
}
.books-header h1 {
  color: #000000;
  font: normal 35px founders-grotesk, "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

.list-columnists {
  position: relative;
}
.list-columnists .author-block.columnist {
  background: none;
  border-top: 1px solid #666;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}
.list-columnists .author-block.columnist h2 {
  text-align: center;
  text-transform: none;
  font-size: 25px;
  margin: 0 0 5px 0;
}
.list-columnists .author-block.columnist h2 a {
  color: #000;
}
.list-columnists .author-block.columnist .bio_image_wrapper {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #f3f3f3;
  overflow: hidden;
  margin: 15px auto;
}
.list-columnists .author-block.columnist .block-sponsor {
  font-weight: 600;
  border-top: 1px solid #666;
  padding-top: 10px;
  margin-top: 10px;
}

.books-subheader {
  display: table;
  table-layout: fixed;
}
.books-subheader .left, .books-subheader .right {
  display: table-cell;
  vertical-align: top;
  width: 50%;
}
.books-subheader .left {
  padding-right: 10px;
}
.books-subheader .right {
  padding-left: 10px;
}
.books-subheader .right h2 {
  font: bold 26px founders-grotesk, "Arial", sans-serif;
  margin: 0;
}
.books-subheader .right p {
  font: 26px founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
}
.books-subheader .right a {
  font-size: 24px;
}

.books-links {
  text-align: center;
}
.books-links h5 {
  font: bold 18px/1.5 founders-grotesk, "Arial", sans-serif;
}
.books-links a + a:before {
  content: " | ";
  color: #000000;
}

.search-header {
  padding: 30px 0;
  text-align: center;
}
.search-header h1 {
  margin: 0;
}
.search-header h1 strong {
  color: #000;
  font-weight: 600;
}
.search-header h1 em {
  display: block;
  font-weight: bold;
  font-style: normal;
}
.search-header .author-image {
  margin: 10px auto;
  text-align: center;
}
.search-header .author-image .bio_image_wrapper {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 0 auto;
  overflow: hidden;
}
.search-header .social {
  position: relative;
}
.search-header .social h3 {
  color: #000;
  font: bold 18px/1.5 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 0;
}
.search-header .social .twitter,
.search-header .social .facebook {
  display: inline-block;
  margin: 10px;
}
.search-header .social .twitter a span,
.search-header .social .facebook a span {
  padding-left: 25px;
  position: relative;
}
.search-header .social .twitter a span:before,
.search-header .social .facebook a span:before {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0;
  top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.search-header .social .twitter a span:before {
  background-image: url(../img/logo-twitter.png);
}
.search-header .social .facebook a span:before {
  background-image: url(../img/logo-facebook.png);
}
.search-header .author-bio .align-left {
  border-top: 1px solid #666;
  text-align: left;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
}

.search-filters {
  text-align: center;
}
.search-filters label.checkbox {
  color: #333333;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.search-filters label.checkbox input[type=checkbox] {
  display: none;
}
.search-filters label.checkbox span {
  background: #70257a;
  display: inline-block;
  margin: -4px 4px 0 0;
  height: 18px;
  vertical-align: middle;
  width: 18px;
}
.search-filters label.checkbox input:checked + span {
  background: #70257a url("../img/checkbox_inverse.png") no-repeat;
}

.result-block {
  border-top: 1px solid #666666;
}
.result-block h2, .result-block p {
  color: #000000;
  margin: 0 0 10px 0;
}
.result-block span.author {
  display: block;
  font: 16px/1 founders-grotesk, "Arial", sans-serif;
  /* letter-spacing: 1px; */
  text-transform: uppercase;
  font-weight: 200;
}
.result-block span.type {
  display: inline-block;
  color: #000000;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.result-block span.type.essay, .result-block span.type.response {
  color: #70257a;
}
.result-block span.type.observation {
  color: #deb65b;
}
.result-block span.date {
  display: inline-block;
  color: #70257a;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.result-block span.type + span.date::before {
  content: " | ";
  display: inline-block;
  margin: 0 6px;
}

.more-posts {
  border-top: 1px solid #70257a;
  display: block;
  font: 30px/1 founders-grotesk, "Arial", sans-serif;
  padding: 10px 0;
  text-align: center;
}
.more-posts:hover {
  cursor: pointer;
}

.auth-overlay {
  border: 1px solid #70257a;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 20px;
}
.auth-overlay h1 {
  line-height: 1;
  margin: 0 0 20px 0;
}
.auth-overlay div a {
  border: 1px solid #70257a;
  display: block;
  font-family: founders-grotesk, "Arial", sans-serif;
  font-weight: 700;
  padding: 10px 30px;
  text-align: center;
}
.auth-overlay div a:nth-child(n+2) {
  background-color: #70257a;
  color: #ffffff;
  margin-top: 10px;
}

.block {
  margin-bottom: 25px;
}
.block .block-header h2 {
  font-family: founders-grotesk, "Arial", sans-serif;
  font-size: 28px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}
.block .block-header p {
  color: #ffffff;
  font-family: founders-grotesk, "Arial", sans-serif;
  font-size: 28px;
  padding: 10px 0;
  line-height: 1;
  margin-bottom: 0;
}
.block .block-body {
  padding: 20px;
}

.block-light-purple {
  background-color: #cebad9;
}
.block-light-purple .block-header {
  margin-bottom: 20px;
}
.block-light-purple .block-header h2 {
  color: #70257A;
  font: 500 24px/1.2 founders-grotesk, "Arial", sans-serif;
  margin: 0;
}
.block-light-purple .block-footer {
  background-color: #70257A;
  border-top: 1px solid #ffffff;
  color: #ffffff;
  padding: 20px;
  text-align: center;
}

.block-orange {
  background-color: #e5b24f;
}
.block-orange .block-header {
  border-bottom: 1px solid #70257a;
  text-align: center;
}
.block-orange .block-header h2 {
  color: #ffffff;
  text-transform: none;
}
.block-orange .block-header p {
  color: #ffffff;
}

.block-paywall-notice {
  padding: 20px;
}
.block-paywall-notice .block-header {
  padding-bottom: 20px;
}
.block-paywall-notice .block-body {
  text-align: center;
}
.block-paywall-notice .block-body p {
  color: #70257a;
  font: 600 24px/1.2 founders-grotesk, "Arial", sans-serif;
}
.block-paywall-notice .block-body p:last-child {
  margin-bottom: 0;
}
.block-paywall-notice .block-body a {
  background-color: #ffffff;
  display: inline-block;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
}
.block-paywall-notice .block-footer {
  border-top: 1px solid #702c87;
  font: 600 16px/1 founders-grotesk, "Arial", sans-serif;
  padding-top: 20px;
  text-align: center;
}
.block-paywall-notice .block-footer a {
  background-color: rgba(255, 255, 255, 0.6);
  color: #70257A;
  display: inline-block;
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
}

.block-paywall-prohibited .block-header h2 {
  color: #000000;
  border-bottom: 1px solid #70257a;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.block-paywall-prohibited .block-header p {
  margin: 0;
  padding: 0;
}

.block-paywall-remaining .block-header h2 {
  color: #ffffff;
}

.benefits-block {
  background-color: #efc877;
  margin-bottom: 1px;
  padding: 20px;
  text-align: center;
}
.benefits-block h2 {
  color: black;
  font: 500 28px/1.2 founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.benefits-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.benefits-block ul li {
  color: #70257a;
  font: 600 21px/1.2 founders-grotesk, "Arial", sans-serif;
  padding: 10px 0;
}
.benefits-block ul li::before {
  content: "• ";
}

.authors-block {
  margin-bottom: 28px;
  text-align: center;
}
.authors-block h2 {
  color: #70257A;
  font: 500 28px/1.2 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.authors-block .author:nth-child(n+2) {
  margin-top: 20px;
}
.authors-block .author img {
  height: 80px;
  margin-bottom: 10px;
  max-width: 100%;
}
.authors-block .author span.name {
  color: #70257A;
  display: block;
  font: 700 16px/1.2 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.authors-block .author span.description {
  display: block;
  font: 500 18px/1.2 founders-grotesk, "Arial", sans-serif;
}

.login-block .form .form-group button {
  background-color: #ffffff;
  color: #70257a;
}
.login-block .form h3 {
  color: black;
  margin: 0;
  font-size: 35px;
  text-transform: none;
}
.login-block .login-footer {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #70257a;
}

.cancel-block h2, .form .cancel-block h2 {
  font-size: 30px;
  margin: 15px;
}
.cancel-block p, .form .cancel-block p {
  font-size: 20px;
  margin-bottom: 10px;
}
.cancel-block div, .form .cancel-block div {
  margin-top: 20px;
}
.cancel-block button, .form .cancel-block button {
  width: 240px;
}

.receipt-block {
  padding: 15px;
}
.receipt-block dt {
  color: #70257a;
  font: 18px/1.5 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}

.incognito-block {
  background-color: #70257a;
  background-image: url(../img/lock.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 180px;
  color: #ffffff;
  font: 20px/1.5 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 20px;
  padding: 20px;
}
.incognito-block h2 {
  font-size: 30px;
  line-height: 1.2;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}
.incognito-block .actions {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.incognito-block .actions a {
  color: #cebad9;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.incognito-block .actions a:first-child {
  background-color: #cebad9;
  color: #70257a;
}

.essay-carousel .item h2 {
  color: #000000;
  font: bold 42px/55px founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
  vertical-align: baseline;
  line-height: 39px;
}
.essay-carousel .item .essay-header {
  margin-bottom: 30px;
}
.essay-carousel .item .meta {
  margin-bottom: 20px;
}
.essay-carousel .item .meta span.type {
  color: #70257a;
  font: bold 18px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.essay-carousel .item .meta span.author {
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.essay-carousel .item .meta span.type + span.author {
  margin-left: 20px;
}
.essay-carousel .item .meta.observation span.type {
  color: #a37b36;
  line-height: 2;
}
.essay-carousel .item .meta.observation span.type strong {
  font-weight: inherit;
  color: #1a709e;
}
.essay-carousel .owl-dots {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.essay-carousel .owl-dots .owl-dot {
  display: table-cell;
}
.essay-carousel .owl-dots .owl-dot span {
  background: #70257a;
  display: block;
  height: 13px;
  opacity: 0.4;
  transition: all 0.25s ease;
}
.essay-carousel .owl-dots .owl-dot.active span {
  opacity: 1;
}
.essay-carousel .owl-dots .owl-dot + .owl-dot {
  padding-left: 5px;
}

.observation-carousel .owl-stage {
  display: flex;
}
.observation-carousel .owl-stage .owl-item {
  display: flex;
  flex: 1 0 auto;
}
.observation-carousel .owl-stage .owl-item .item h2 {
  color: #a37b36;
  font: bold 21px/1 founders-grotesk, "Arial", sans-serif;
  margin: 10px 0 10px 0;
}
.observation-carousel .owl-stage .owl-item .item .meta span.author {
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.observation-carousel .owl-stage .owl-item .more {
  display: flex;
  border-left: 1px solid #000000;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.observation-carousel .owl-stage .owl-item .more h2 {
  font-size: 26px;
  font-weight: 500;
}

.picks-header .picks-title {
  color: #b4b4b4;
  font: 600 17px/1 founders-grotesk;
  margin: 0 0 20px 0;
  text-transform: uppercase;
}
.picks-header .picks-title .date {
  color: #70257a;
}

.picks-carousel .owl-stage {
  display: flex;
}
.picks-carousel .owl-stage .owl-item {
  border-left: 1px solid #919191;
  padding: 0 10px;
  display: flex;
  flex: 1 0 auto;
}
.picks-carousel .owl-stage .owl-item .item {
  display: flex;
  width: 100%;
}
.picks-carousel .owl-stage .owl-item .item h2 {
  color: #4e4e4e;
  font: 600 21px/1 founders-grotesk;
  margin: 0;
}
.picks-carousel .owl-stage .owl-item .more {
  justify-content: center;
  align-items: center;
}
.picks-carousel .owl-stage .owl-item .more h2 {
  font-size: 26px;
  font-weight: 500;
}

.featured-carousel .item h2 {
  color: #a37b36;
  font: bold 30px/1 founders-grotesk, "Arial", sans-serif;
  margin: 0 0 10px 0;
  vertical-align: baseline;
}
.featured-carousel .item .meta span.author {
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.featured-carousel .item .meta.bg-binding {
  font: 200 16px/1 founders-grotesk, "Arial", sans-serif;
}

.topic-carousel h2 {
  color: #70257a;
  margin: 10px 0;
  text-transform: none;
}
.topic-carousel .owl-dots {
  text-align: center;
}
.topic-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.topic-carousel .owl-dots .owl-dot:nth-child(n+2) {
  margin-left: 10px;
}
.topic-carousel .owl-dots .owl-dot span {
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  height: 10px;
  width: 10px;
}
.topic-carousel .owl-dots .owl-dot.active span {
  background-color: #70257a;
}

.testimonials-carousel {
  background-color: #efc877;
  margin-bottom: 25px;
  padding: 20px;
}
.testimonials-carousel p span.content {
  display: block;
  quotes: "“" "”" "‘" "’";
}
.testimonials-carousel p span.content::before {
  content: open-quote;
}
.testimonials-carousel p span.content::after {
  content: close-quote;
}
.testimonials-carousel .owl-dots {
  text-align: center;
}
.testimonials-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.testimonials-carousel .owl-dots .owl-dot:nth-child(n+2) {
  margin-left: 10px;
}
.testimonials-carousel .owl-dots .owl-dot span {
  background-color: #ffffff;
  border-radius: 50%;
  display: block;
  height: 10px;
  width: 10px;
}
.testimonials-carousel .owl-dots .owl-dot.active span {
  background-color: #70257a;
}

.authors-carousel {
  margin-bottom: 25px;
  padding: 20px;
}
.authors-carousel img {
  display: inline !important;
  width: auto !important;
}
.authors-carousel .owl-dots {
  margin-top: 10px;
  text-align: center;
}
.authors-carousel .owl-dots .owl-dot {
  display: inline-block;
}
.authors-carousel .owl-dots .owl-dot:nth-child(n+2) {
  margin-left: 10px;
}
.authors-carousel .owl-dots .owl-dot span {
  background-color: #70257a;
  border-radius: 50%;
  display: block;
  height: 10px;
  opacity: 0.4;
  width: 10px;
}
.authors-carousel .owl-dots .owl-dot.active span {
  background-color: #70257a;
  opacity: 1;
}

.input-append {
  background: #ffffff;
  display: block;
  height: 43px;
  padding: 0 20px;
}
.input-append input {
  background: transparent;
  border: none;
  height: 43px;
  width: calc(100% - 80px);
}
.input-append input:focus {
  outline: none;
}
.input-append button {
  border: none;
  background: transparent;
  font: 600 18px founders-grotesk;
  height: 43px;
  width: 80px;
  padding: 0;
  text-align: right;
  text-transform: uppercase;
  vertical-align: top;
}

.checkboxes {
  display: flex;
  margin-top: 14px;
  justify-content: center;
}

.checkbox {
  color: #ffffff;
  display: inline-block;
  font: 500 18px/26px founders-grotesk;
  line-height: 30px;
  min-height: 30px;
  padding-left: 40px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
}
.checkbox span {
  background: #ffffff;
  display: inline-block;
  height: 30px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}
.checkbox small {
  display: block;
}
.checkbox input[type=checkbox],
.checkbox input[type=radio] {
  display: none;
}
.checkbox input[type=checkbox]:checked + span,
.checkbox input[type=radio]:checked + span {
  background: #ffffff url(../img/checkbox.png) no-repeat center;
}
.checkbox.checkbox-sm {
  padding-left: 30px;
  min-height: 20px;
}
.checkbox.checkbox-sm span {
  height: 22px;
  width: 22px;
}
.checkbox.checkbox-sm input[type=checkbox]:checked + span {
  background-size: 15px;
}

.checkbox + .checkbox {
  margin-left: 20px;
}

.checkboxes-stacked {
  align-items: center;
  flex-direction: column;
}
.checkboxes-stacked.checkboxes-left {
  align-items: flex-start;
}
.checkboxes-stacked .checkbox + .checkbox {
  margin-left: 0;
  margin-top: 10px;
}

.checkboxes-plan .checkbox {
  color: #70257A;
  font: 600 18px/18px founders-grotesk, "Arial", sans-serif;
  line-height: 1;
  width: 220px;
}
.checkboxes-plan .checkbox small {
  color: #000000;
  display: block;
  font-weight: 300;
  text-transform: none;
}

.checkboxes-newsletter .checkbox {
  width: 220px;
  color: #70257a;
  font: 600 18px/18px founders-grotesk, Arial, sans-serif;
  line-height: 30px;
}

.form h3 {
  color: #70257a;
  font: 500 28px/1.2 founders-grotesk, "Arial", sans-serif;
  margin: 0 0 20px 0;
  text-align: center;
  text-transform: uppercase;
}
.form p {
  font: 500 16px/1 founders-grotesk, "Arial", sans-serif;
  text-align: center;
}
.form p a {
  color: inherit;
}
.form p.terms {
  font-size: 20px;
}
.form p.info {
  color: white;
  font: 16px/1.2 tiempos-text, "Times New Roman", serif;
}
.form .form-group:nth-child(n+2) {
  margin-top: 15px;
}
.form .form-group.form-group-center {
  text-align: center;
}
.form .form-group a {
  color: #000000;
  display: block;
  font: 500 18px/1 founders-grotesk, "Arial", sans-serif;
  letter-spacing: 1px;
  position: relative;
  text-align: center;
}
.form .form-group .error a {
  color: #70257a;
  text-align: left;
  display: inline;
  letter-spacing: 0px;
  font: 500 16px/1.2 founders-grotesk, Arial, sans-serif;
}
.form .form-group h4 {
  color: #ffffff;
  font: 500 16px/1.2 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.form .form-group input,
.form .form-group select,
.form .form-group textarea {
  border: none;
  box-sizing: border-box;
  font: 20px/1.2 founders-grotesk, "Arial", sans-serif;
  padding: 10px 20px;
  resize: none;
  width: 100%;
}
.form .form-group .select {
  background-color: #ffffff;
  display: block;
  position: relative;
}
.form .form-group .select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding-right: 40px;
  position: relative;
  text-transform: uppercase;
}
.form .form-group .select::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MDAgNTAwIj48cGF0aCBkPSJNNDQ4LjkgMTQ0LjVjLTYuNS02LjQtMTctNi40LTIzLjQuMUwyNTAgMzIxLjMgNzQuNCAxNDQuNmMtNi40LTYuNS0xNi45LTYuNS0yMy40LS4xcy02LjUgMTYuOS0uMSAyMy40bDE4Ni4zIDE4Ny42YzMuMiAzLjMgNy41IDQuOSAxMS43IDQuOS4zIDAgLjYtLjIgMS0uMi4zIDAgLjcuMiAxIC4yIDQuMiAwIDguNS0xLjYgMTEuNy00LjlMNDQ5IDE2Ny44YzYuNC02LjUgNi40LTE2LjktLjEtMjMuM3oiIGZpbGw9IiM3MTJjODYiLz48L3N2Zz4=);
  background-position: center;
  background-size: cover;
  content: " ";
  display: block;
  height: 20px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
}
.form .form-group button, .form .form-group .btn {
  background-color: #70257a;
  border: none;
  color: #ffffff;
  font: 600 16px/1.2 founders-grotesk, "Arial", sans-serif;
  padding: 10px 40px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}
.form .form-group button.btn-blue, .form .form-group button.btn-green, .form .form-group button.btn-red, .form .form-group .btn.btn-blue, .form .form-group .btn.btn-green, .form .form-group .btn.btn-red {
  color: rgba(255, 255, 255, 0.8);
}
.form .form-group button.btn-blue, .form .form-group .btn.btn-blue {
  background-color: #0367b1;
}
.form .form-group button.btn-green, .form .form-group .btn.btn-green {
  background-color: #05930f;
}
.form .form-group button.btn-red, .form .form-group .btn.btn-red {
  background-color: #c74f4f;
}
.form .form-group button.btn-lg, .form .form-group .btn.btn-lg {
  font-size: 20px;
  padding: 10px 60px;
}
.form .form-group .btn + .btn {
  margin-top: 10px;
}
.form .form-group .stripe-element {
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 10px 20px;
  width: 100%;
}
.form .form-group span.error {
  color: #f44336;
  display: block;
  font: 500 16px/1.2 founders-grotesk, "Arial", sans-serif;
  text-align: left;
}

.form-alert {
  color: #ffffff;
  padding: 10px 20px;
  text-align: center;
}
.form-alert.form-alert-error {
  background-color: #e57373;
}
.form-alert.form-alert-success {
  background-color: #81c784;
}

.form-processing button {
  align-items: center;
  display: inline-flex;
  pointer-events: none;
}
.form-processing button::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwcHgiICBoZWlnaHQ9IjIwMHB4IiAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiIGNsYXNzPSJsZHMtcm9sbGluZyIgc3R5bGU9ImJhY2tncm91bmQ6IHJnYmEoMCwgMCwgMCwgMCkgbm9uZSByZXBlYXQgc2Nyb2xsIDAlIDAlOyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgZmlsbD0ibm9uZSIgbmctYXR0ci1zdHJva2U9Int7Y29uZmlnLmNvbG9yfX0iIG5nLWF0dHItc3Ryb2tlLXdpZHRoPSJ7e2NvbmZpZy53aWR0aH19IiBuZy1hdHRyLXI9Int7Y29uZmlnLnJhZGl1c319IiBuZy1hdHRyLXN0cm9rZS1kYXNoYXJyYXk9Int7Y29uZmlnLmRhc2hhcnJheX19IiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIj48L2FuaW1hdGVUcmFuc2Zvcm0+PC9jaXJjbGU+PC9zdmc+);
  background-position: center;
  background-size: cover;
  content: "";
  height: 16px;
  margin-left: 10px;
  width: 18px;
}

.form ::placeholder {
  color: #622f84; /*#cebad9;*/
  text-transform: uppercase;
}

.auth-form {
  margin-bottom: 20px;
}
.auth-form label {
  display: block;
  font-family: founders-grotesk, "Arial", sans-serif;
  font-weight: 700;
  margin-bottom: 10px;
}
.auth-form input {
  box-sizing: border-box;
  display: block;
  padding: 10px 20px;
  width: 100%;
}
.auth-form span.error {
  color: #f44336;
  font-size: 12px;
}

.auth-form button {
  background-color: #70257A;
  border: none;
  color: #ffffff;
  display: inline-block;
  font-family: founders-grotesk, "Arial", sans-serif;
  font-weight: 700;
  padding: 10px 30px;
}

.auth-form > .form-group:nth-child(n+2) {
  margin-top: 15px;
}

.auth-actions {
  margin-bottom: 30px;
}
.auth-actions a {
  color: #9e9e9e;
  display: inline-block;
  font-size: 12px;
}
.auth-actions a:nth-child(n+2)::before {
  content: " | ";
  padding-left: 10px;
  padding-right: 10px;
}

.auth-message {
  color: #ffffff;
  padding: 10px 20px;
}
.auth-message.auth-message-error {
  background-color: #f44336;
}
.auth-message.auth-message-success {
  background-color: #8bc34a;
}

.subscribe-form {
  background-color: #e3d4e4;
  display: block;
  text-align: center;
}
.subscribe-form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.subscribe-form ul li {
  /* color: #ffffff; */
  font: 22px/1.2 founders-grotesk, "Arial", sans-serif;
}
.subscribe-form ul li::before {
  content: "• ";
}
.subscribe-form ul li:nth-child(n+2) {
  margin-top: 10px;
}
.subscribe-form .section {
  border-bottom: 1px solid #ffffff;
  padding: 20px;
}
.subscribe-form .form-group h4 {
  color: #000000;
  font: 300 24px/1.2 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.subscribe-form .plan .title,
.subscribe-form .plan .price {
  font: 600 18px/18px founders-grotesk, "Arial", sans-serif;
  display: block;
}
.subscribe-form .plan .title {
  color: #70257A;
  text-transform: uppercase;
}
.subscribe-form .plan .price {
  font-weight: 300;
}

.ad-container img {
  display: block;
  margin: 20px auto 10px auto;
  max-width: 100%;
  height: auto;
}

.ad-modal {
  max-width: 498px;
  padding: 0;
}
.ad-modal img {
  vertical-align: middle;
}
.ad-modal .remodal-close {
  color: #fff;
  right: 0;
  left: initial;
}

#header,
#subscribe-header {
  width: 100%;
  z-index: 9999;
}
#header.toggled .header-top,
#subscribe-header.toggled .header-top {
  padding-bottom: 0;
}
#header.toggled #submenu,
#subscribe-header.toggled #submenu {
  display: none;
}
#header.toggled #main-menu,
#subscribe-header.toggled #main-menu {
  display: block;
}
#header.toggled .toggle,
#subscribe-header.toggled .toggle {
  background: #000000;
}
#header.up,
#subscribe-header.up {
  top: -71px;
}
#header.up #main-menu,
#subscribe-header.up #main-menu {
  display: none;
}
#header .header-top,
#subscribe-header .header-top {
  background: #70257a;
}
#header .container,
#subscribe-header .container {
  text-align: center;
}
#header .container .toggle,
#subscribe-header .container .toggle {
  display: block;
  color: #ffffff;
  font-size: 23px;
  line-height: 40px;
  height: 40px;
  width: 73px;
  position: absolute;
  left: 0;
}
#header .container .logo,
#subscribe-header .container .logo {
  display: inline-block;
  max-width: 100px;
  margin: 6px 0;
}
#header .container .logo img,
#subscribe-header .container .logo img {
  width: 100%;
  height: auto;
}
#header .container .header-actions,
#subscribe-header .container .header-actions {
  position: absolute;
  top: 5px;
  right: 21px;
}
#header .container .header-actions a,
#subscribe-header .container .header-actions a {
  color: #cdbcd5;
  font: 600 14px/1 founders-grotesk, "Arial", sans-serif;
  line-height: 29px;
  padding: 6px 10px;
  text-transform: uppercase;
}
@media screen and (min-width: 390px) {
  #header .container .header-actions a,
  #subscribe-header .container .header-actions a {
    font: 600 11px/1 founders-grotesk, "Arial", sans-serif;
  }
}
#header .container .header-actions .donate,
#subscribe-header .container .header-actions .donate {
  background-color: #cdbcd5;
  color: #70257a;
}

#header {
  position: fixed;
  top: 0;
  transition: top 0.2s ease-in-out;
}
#header .header-top {
  padding-bottom: 14px;
}

#subscribe-header .header-top {
  background-color: #ffffff;
  padding-bottom: 10px;
  padding-top: 10px;
}
#subscribe-header .container {
  text-align: left;
}
#subscribe-header .container .logo {
  max-width: 200px;
}
#subscribe-header .container .header-actions {
  top: 20px;
}
#subscribe-header .container .header-actions .help-link {
  color: #70257a;
}
#subscribe-header .container .header-actions .help-link::before {
  content: "\e901";
  font-family: "icomoon";
  margin-right: 6px;
  vertical-align: middle;
}
#subscribe-header .container .header-actions .help-link span {
  text-decoration: underline;
}

#main-menu {
  background: #000000;
  display: none;
  padding: 22px 0;
  /*.padding {
    padding: 22px 0;
  }*/
}
#main-menu .container + .container {
  margin-top: 15px;
}
#main-menu h2 {
  color: #ffffff;
  font: 600 18px/1 founders-grotesk;
  margin: 0 0 15px 0;
}
#main-menu h2.title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 5px;
  font-size: 18px;
  margin-bottom: 5px;
}
#main-menu .menu, #main-menu .submenu {
  list-style: none;
  margin: 0;
  padding: 0;
}
#main-menu .menu li > a {
  color: #ffffff;
  font: 600 18px/1.25 founders-grotesk;
}
#main-menu .submenu > li > a {
  color: #ffffff;
  font: 500 18px/1.25 founders-grotesk;
}
#main-menu .social-menu {
  text-align: center;
}
#main-menu .social-menu li {
  display: inline-block;
}
#main-menu .social-menu li a {
  font-size: 24px;
}
#main-menu .social-menu li + li {
  margin-left: 40px;
}
#main-menu .column {
  float: left;
  text-align: center;
  width: 50%;
}
#main-menu.toggled {
  display: block;
}

#submenu {
  margin-top: -14px;
}
#submenu .container nav {
  background: #ffffff;
  border-right: 2px solid #70257a;
  border-left: 2px solid #70257a;
  text-align: center;
}
#submenu .container a {
  display: inline-block;
  color: #cebad9;
  font: 600 12px/1 founders-grotesk, "Arial", sans-serif;
  letter-spacing: 2px;
  padding: 10px 10px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  width: 33.3333%;
}
#submenu .container a.active {
  color: #70257a;
}
@media screen and (max-width: 375px) {
  #submenu .container a {
    width: 40%;
    text-align: center;
  }
  #submenu .container a:first-child, #submenu .container a:last-child {
    width: 30%;
  }
}
#submenu .container a + a {
  border-left: 2px solid #70257a;
}
#submenu.toggled {
  display: none;
}

#footer {
  background: #70257a;
  padding: 40px 0;
}
#footer .logo {
  display: block;
  max-width: 100px;
  margin: 0 auto 40px auto;
}
#footer .logo img {
  width: 100%;
  height: auto;
}
#footer nav {
  margin-bottom: 40px;
  text-align: center;
}
#footer nav a {
  display: block;
  color: #ffffff;
  font: 400 24px/1 founders-grotesk, "Arial", sans-serif;
  letter-spacing: 2px;
  text-decoration: none;
}
#footer nav a + a {
  margin-top: 20px;
}
#footer small.copyright {
  color: #ffffff;
  display: block;
  font: 400 18px/1 founders-grotesk, "Arial", sans-serif;
  text-align: center;
}

.article {
  padding: 30px 0;
}

.share a {
  background: #70257a;
  border-radius: 50%;
  color: #ffffff;
  display: block;
  font: bold 12px/100px founders-grotesk, "Arial", sans-serif;
  letter-spacing: 2px;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  text-align: center;
  text-transform: uppercase;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 20px;
  z-index: 9999;
}
.modal-overlay.hidden {
  display: none;
}
.modal-overlay .modal {
  background: #ffffff;
  box-shadow: 0 0 10px #000000;
  position: relative;
  width: 100%;
}
.modal-overlay .modal .close {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background: url(../img/modal-close.png) no-repeat 0 0;
}

.email-modal-overlay .header {
  background: #70257a;
  color: #ffffff;
  font: 22px/1 founders-grotesk, "Arial", sans-serif;
  margin: 0;
  padding: 5px 6px;
  text-align: center;
  text-transform: uppercase;
}
.email-modal-overlay form {
  padding: 20px;
}
.email-modal-overlay form p {
  margin-bottom: 20px;
}
.email-modal-overlay form label {
  display: block;
  margin: 15px 0 3px 0;
  font: 16px/1 founders-grotesk, "Arial", sans-serif;
  color: #344a53;
}
.email-modal-overlay form input[type=text],
.email-modal-overlay form input[type=email],
.email-modal-overlay form textarea {
  display: block;
  padding: 5px;
  width: 100%;
  border: 1px solid #DDD;
}
.email-modal-overlay form textarea {
  height: 100px;
  resize: none;
}
.email-modal-overlay form input[type=submit] {
  border: none;
  background: white;
  color: #70257a;
  font: 16px/1 founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  margin: 15px 0 5px 0;
  cursor: pointer;
}

.subscribe-modal-overlay .modal {
  background-color: #e5b24f;
  padding: 25px;
  text-align: center;
}
.subscribe-modal-overlay .modal .header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  color: #70257A;
  font: 500 36px/1.2 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 25px;
  padding-bottom: 25px;
  text-transform: none;
}
.subscribe-modal-overlay .modal .content {
  font: 600 22px/1.2 founders-grotesk, "Arial", sans-serif;
}
.subscribe-modal-overlay .modal .content p {
  margin-bottom: 0;
}
.subscribe-modal-overlay .modal .content ul {
  list-style: none;
  margin: 0 0 25px 0;
  padding: 0;
}
.subscribe-modal-overlay .modal .content ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  border-top: none;
  color: #70257A;
  font: 600 22px/1.2 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 0;
  padding: 25px 0;
}
.subscribe-modal-overlay .modal .content ul li:nth-child(even) {
  color: #000000;
}
.subscribe-modal-overlay .modal .content .btn-group a.btn {
  background-color: #ffffff;
  display: block;
  color: #70257A;
  font: 600 18px/1.2 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 20px;
  padding: 10px 40px;
  text-transform: uppercase;
}
.subscribe-modal-overlay .modal .content .btn-group a.btn.btn-alt {
  background-color: rgba(255, 255, 255, 0.6);
}
.subscribe-modal-overlay .modal .footer {
  color: #000000;
  font: 500 18px/1 founders-grotesk, "Arial", sans-serif;
}
.subscribe-modal-overlay .modal .footer a {
  color: inherit;
}

.sticky-notice {
  background-color: #e5b24f;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 9999;
}
.sticky-notice .sticky-notice-header {
  cursor: pointer;
  padding: 20px 60px 20px 20px;
  position: relative;
}
.sticky-notice .sticky-notice-header::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjg1IDI4NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDE0Ij48cGF0aCBkPSJNMTQyLjQ2MyA1Ni43NDhsMTQ0LjEzNiAxNDQuMTM2LTI3LjI5NCAyNy4yOTQtMTE2LjgzOS0xMTYuODM5TDI1LjYyNCAyMjguMTgxLTEuNjcgMjAwLjg4NyAxMTUuMTczIDg0LjA0NWwtLjAwNC0uMDAzIDI3LjI5NC0yNy4yOTR6IiBmaWxsPSIjNzAyNTdhIi8+PC9zdmc+);
  background-position: center;
  background-size: cover;
  content: "";
  display: block;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  width: 16px;
}
.sticky-notice .sticky-notice-header h2 {
  color: #ffffff;
  font: 400 28px/1 founders-grotesk, "Arial", sans-serif;
  margin: 0;
}
.sticky-notice.expanded .sticky-notice-header::after {
  transform: translateY(-50%) rotateZ(180deg);
}
.sticky-notice .sticky-notice-content {
  padding: 0 20px;
}
.sticky-notice .sticky-notice-content .sticky-notice-body {
  border-top: 1px solid #70257a;
  color: #70257a;
  font: 600 24px/1.2 founders-grotesk, Arial, sans-serif;
  padding-top: 10px;
}
.sticky-notice .sticky-notice-content .sticky-notice-body p {
  font: 600 24px/1.2 founders-grotesk, "Arial", sans-serif;
}
.sticky-notice .sticky-notice-content .sticky-notice-body a {
  background-color: #ffffff;
  display: inline-block;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
}
.sticky-notice .sticky-notice-content .sticky-notice-footer {
  border-top: 1px solid #702c87;
  font: 18px/1 founders-grotesk, "Arial", sans-serif;
  padding: 20px;
  text-align: center;
}
.sticky-notice .sticky-notice-content .sticky-notice-footer a {
  color: #000000;
}
.sticky-notice .sticky-notice-content .btn-group a.btn {
  background-color: #ffffff;
  display: block;
  color: #70257A;
  font: 600 18px/1.2 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 20px;
  padding: 10px 40px;
  text-transform: uppercase;
}
.sticky-notice .sticky-notice-content .btn-group a.btn.btn-alt {
  background-color: rgba(255, 255, 255, 0.6);
}

.sticky-notice.sticky-notice-v1 .sticky-notice-header h2,
.sticky-notice.sticky-notice-v2 .sticky-notice-header h2 {
  text-transform: none;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content p,
.sticky-notice.sticky-notice-v2 .sticky-notice-content p {
  color: #000000;
  font-weight: 400;
  font-size: 20px;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .btn-group a.btn,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .btn-group a.btn {
  margin-bottom: 0;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .btn-group a.btn.btn-alt,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .btn-group a.btn.btn-alt {
  background-color: transparent;
  color: #000000;
  font-size: 16px;
  text-transform: none;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .btn-group a.btn.btn-alt em,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .btn-group a.btn.btn-alt em {
  color: #70257a;
  font-style: normal;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .counter,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter {
  display: block;
  margin-bottom: 10px;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .counter span.count,
.sticky-notice.sticky-notice-v1 .sticky-notice-content .counter span.title,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter span.count,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter span.title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .counter span.count,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter span.count {
  background-color: #ffffff;
  border-radius: 50%;
  color: #e5b24f;
  line-height: 80px;
  margin-bottom: 10px;
  text-align: center;
  width: 80px;
  height: 80px;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .counter span.title,
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter span.title {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}

.sticky-notice.sticky-notice-v1 .sticky-notice-header h2 {
  color: #000000;
}
.sticky-notice.sticky-notice-v1 .sticky-notice-content .btn-group a.btn {
  background-color: #70257a;
  color: #ffffff;
}

.sticky-notice.sticky-notice-v2 {
  background-color: #ffffff;
  border: 4px solid #e5b24f;
}
.sticky-notice.sticky-notice-v2 .sticky-notice-header h2 {
  color: #70257a;
}
.sticky-notice.sticky-notice-v2 .sticky-notice-content p {
  color: #000000;
}
.sticky-notice.sticky-notice-v2 .sticky-notice-content .btn-group a.btn {
  background-color: #e5b24f;
  color: #ffffff;
}
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter span.count {
  background-color: #e5b24f;
  color: #ffffff;
  line-height: 80px;
}
.sticky-notice.sticky-notice-v2 .sticky-notice-content .counter span.title {
  color: #000000;
}

.subscribe-step-1 {
  margin-bottom: 30px;
  padding: 30px;
}

.subscribe-banner {
  background-color: #70257a;
  color: #ffffff;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
  position: relative;
}
.subscribe-banner .subscribe-banner-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0.15;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.subscribe-banner .subscribe-banner-content {
  position: relative;
  z-index: 1;
}
.subscribe-banner .subscribe-banner-content h1 {
  color: inherit;
  line-height: 1.3;
  margin: 0 0 10px 0;
}
.subscribe-banner .subscribe-banner-content h1 em {
  color: #e5b24f;
  font-style: inherit;
  text-decoration: underline;
}
.subscribe-banner .subscribe-banner-content .form .form-group input {
  color: #000000;
  font-size: 22px;
  height: 50px;
  line-height: 1;
  text-align: center;
}
.subscribe-banner .subscribe-banner-content .form .form-group input::placeholder {
  color: #707070;
  font-size: 22px;
}
.subscribe-banner .subscribe-banner-content .form .form-group .btn {
  font-size: 20px;
}
.subscribe-banner .subscribe-banner-content .form .form-group span.error {
  background-color: #ffab91;
  color: #5e1600;
  line-height: 1;
  padding: 10px;
  text-align: center;
}
.subscribe-banner .subscribe-banner-content .form .checkboxes-buttons .checkbox-button span {
  background-color: #ffffff;
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
.subscribe-banner .subscribe-banner-content .form .checkboxes-buttons .checkbox-button span small {
  color: #70257a;
  font-size: 27px;
  font-weight: 600;
  margin-top: 6px;
}
.subscribe-banner .subscribe-banner-content .form .checkboxes-buttons .checkbox-button input[type=checkbox]:checked + span,
.subscribe-banner .subscribe-banner-content .form .checkboxes-buttons .checkbox-button input[type=radio]:checked + span {
  background-color: #e5B14f;
}
.subscribe-banner .subscribe-banner-content p.login-text {
  color: #fbf0da;
  font-size: 1.2em;
  margin: 15px 0 0 0;
}
.subscribe-banner .subscribe-banner-content p.login-text a {
  color: #d6b24f;
  text-decoration: underline;
}
.subscribe-banner.flash-sale .subscribe-banner-content h1 {
  display: inline-block;
  font-family: tiempos-text;
  position: relative;
}
.subscribe-banner.flash-sale .subscribe-banner-content h1::before, .subscribe-banner.flash-sale .subscribe-banner-content h1::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOS45MSA4MC42MyI+PHBhdGggZmlsbD0iI2Y0YWUxYSIgZD0iTTE1LjEgNC4wNEwzLjUxIDQyLjM0bDEyLjk0LTIuOTdMMCA4MC42M2wzOS45MS01Ny45OC0xOC45NSA0LjA2TDI4Ljg1IDB6Ii8+PC9zdmc+);
  background-position: center;
  background-size: cover;
  content: "";
  display: block;
  height: 50px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
}
.subscribe-banner.flash-sale .subscribe-banner-content h1::before {
  left: -40px;
}
.subscribe-banner.flash-sale .subscribe-banner-content h1::after {
  right: -40px;
}

.subscribe-benefit {
  font-size: 18px;
}
.subscribe-benefit .subscribe-benefit-icon {
  background-color: #deb65b;
  border-radius: 50%;
  height: 100px;
  margin: 0 auto 20px auto;
  position: relative;
  width: 100px;
}
.subscribe-benefit .subscribe-benefit-icon.subscribe-benefit-icon-purple {
  background-color: #70257a;
}
.subscribe-benefit .subscribe-benefit-icon span {
  color: #fff;
  font-size: 60px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.subscribe-callout {
  background-color: #fbf0da;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
}
.subscribe-callout a {
  color: #e6b24f;
  text-decoration: underline;
}

.subscribe-footer {
  background-color: #70257a;
  color: #ceb5d2;
  margin-left: -30px;
  margin-right: -30px;
  padding: 30px;
}

.subscribe-step-2 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.payment-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0 40px;
  align-items: center;
}

.coupon-element {
  display: flex;
  align-items: stretch;
  justify-content: space-around;
}
.coupon-element input {
  margin-right: 8px;
}

.subscribe-benefits {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
}
.subscribe-benefits li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  border-top: none;
  color: #70257A;
  font: 600 24px/1.2 founders-grotesk, "Arial", sans-serif;
  margin-bottom: 0;
  padding: 10px 0;
}
.subscribe-benefits li:nth-child(even) {
  color: #000000;
}

.checkboxes-buttons .checkbox-button {
  display: block;
}
.checkboxes-buttons .checkbox-button:nth-of-type(n+2) {
  margin-top: 10px;
}
.checkboxes-buttons .checkbox-button span {
  border: 1px solid #70257a;
  color: #70257a;
  display: block;
  font: 600 18px/1 founders-grotesk, "Arial", sans-serif;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
}
.checkboxes-buttons .checkbox-button span small {
  display: block;
  font-size: 24px;
  font-weight: 300;
  line-height: 1;
  text-transform: none;
}
.checkboxes-buttons .checkbox-button input[type=checkbox],
.checkboxes-buttons .checkbox-button input[type=radio] {
  display: none;
}
.checkboxes-buttons .checkbox-button input[type=checkbox]:checked + span,
.checkboxes-buttons .checkbox-button input[type=radio]:checked + span {
  background-color: #70257a;
  color: #ffffff;
}

.subscribe-accordion-item:nth-of-type(n+2) {
  border-top: 2px solid white;
}
.subscribe-accordion-item .subscribe-accordion-header {
  align-items: center;
  background-color: #70257A;
  color: #cebad9;
  display: flex;
  font: 25px/1 founders-grotesk, "Arial", sans-serif;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
}
.subscribe-accordion-item .subscribe-accordion-header::after {
  display: inline-block;
  border-right: 2px solid #cebad9;
  border-bottom: 2px solid #cebad9;
  width: 13px;
  height: 13px;
  content: "";
  display: inline-block;
  margin-left: auto;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.subscribe-accordion-item .subscribe-accordion-content {
  background-color: #fbf0da;
  display: none;
  padding: 30px;
}
.subscribe-accordion-item .btn.disabled {
  opacity: 0.8;
  pointer-events: none;
}
.subscribe-accordion-item .btn.disabled::after {
  content: "";
  display: inline-block;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQiIGNsYXNzPSJsZHMtcm9sbGluZyIgc3R5bGU9ImJhY2tncm91bmQ6MCAwIj48Y2lyY2xlIGN4PSI1MCIgY3k9IjUwIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMTAiIHI9IjM1IiBzdHJva2UtZGFzaGFycmF5PSIxNjQuOTMzNjE0MzEzNDY0MTUgNTYuOTc3ODcxNDM3ODIxMzgiIHRyYW5zZm9ybT0icm90YXRlKDU5LjkyNiA1MCA1MCkiPjxhbmltYXRlVHJhbnNmb3JtIGF0dHJpYnV0ZU5hbWU9InRyYW5zZm9ybSIgdHlwZT0icm90YXRlIiBjYWxjTW9kZT0ibGluZWFyIiB2YWx1ZXM9IjAgNTAgNTA7MzYwIDUwIDUwIiBrZXlUaW1lcz0iMDsxIiBkdXI9IjFzIiBiZWdpbj0iMHMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9jaXJjbGU+PC9zdmc+);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 15px;
  margin-left: 10px;
  vertical-align: middle;
  width: 15px;
}
.subscribe-accordion-item .form .form-group p {
  text-align: left;
}
.subscribe-accordion-item.active .subscribe-accordion-header {
  color: #ffffff;
}
.subscribe-accordion-item.active .subscribe-accordion-header::after {
  border-color: white;
  transform: rotate(45deg);
}
.subscribe-accordion-item.active .subscribe-accordion-content {
  display: block;
}

.subscribe-sidebar {
  margin-top: 30px;
}
.subscribe-sidebar .subscribe-widget {
  background-color: #fbf0da;
}
.subscribe-sidebar .subscribe-widget:nth-of-type(n+2) {
  margin-top: 15px;
}
.subscribe-sidebar .subscribe-widget .subscribe-widget-title {
  background-color: #E9B646;
  border-bottom: 2px solid #ffffff;
  color: #ffffff;
  font: 25px/1 founders-grotesk, "Arial", sans-serif;
  padding: 15px 20px;
  text-transform: uppercase;
}
.subscribe-sidebar .subscribe-widget .subscribe-widget-content {
  padding: 20px;
}
.subscribe-sidebar .subscribe-widget .subscribe-widget-content dl {
  margin: 0;
}

.countdown {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
}
.countdown span {
  border: 2px solid #ffffff;
  display: block;
  font-size: 24px;
  font-weight: bold;
  line-height: 50px;
  margin: 0 10px 30px 10px;
  position: relative;
  text-align: center;
  width: 50px;
}
.countdown span::after {
  bottom: 0;
  display: block;
  font-size: 10px;
  left: 0;
  line-height: 1;
  padding: 10px 0;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  transform: translateY(100%);
}
.countdown span.hours::after {
  content: "Hours";
}
.countdown span.minutes::after {
  content: "Minutes";
}
.countdown span.seconds::after {
  content: "Seconds";
}

.flash-sale-content h2 {
  color: #deb65b;
  font-size: 44px;
  margin: 0;
}
.flash-sale-content p {
  font-size: 20px;
}

.switcher .switcher-nav {
  list-style: none;
  margin: 20px 0;
  padding: 0;
}
.switcher .switcher-nav li {
  font: 13px/1 founders-grotesk, "Arial", sans-serif;
  font-weight: normal;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 1;
  margin: 0;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
}
.switcher .switcher-nav li a {
  color: #cebad9;
  cursor: pointer;
}
.switcher .switcher-nav li.active a {
  color: #70257a;
}
.switcher .switcher-nav li:nth-child(n+2) {
  border-top: 1px solid #f5f5f5;
}

.table {
  background-color: rgba(255, 255, 255, 0.4);
  border-collapse: collapse;
  width: 100%;
}
.table thead tr th {
  background-color: #70257a;
  color: #ffffff;
  font: 13px/1 founders-grotesk, "Arial", sans-serif;
  font-weight: 600;
  padding: 10px;
  text-align: left;
  text-transform: uppercase;
}
.table tbody tr:nth-child(n+2) {
  border-top: 2px solid #f5f5f5;
}
.table tbody tr td {
  font: 13px/1 founders-grotesk, "Arial", sans-serif;
  font-weight: 600;
  padding: 10px;
  text-align: left;
}

.account-container {
  padding: 40px 0;
}
.account-container .block-header {
  background-color: #E9B646;
  color: white;
  font: 35px founders-grotesk, "Arial", sans-serif;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid white;
}
.account-container .block-header.accordion-header:after {
  content: " ";
  background: url(/assets/img/accordion-closed.svg) no-repeat;
  background-size: 25px 28px;
  height: 25px;
  width: 37px;
  display: inline-block;
  margin: 7px 0 -2px 10px;
}
.account-container .block-header.accordion-header.active {
  color: #70257a;
}
.account-container .block-header.accordion-header.active:after {
  background: url(/assets/img/accordion-opened.svg) no-repeat;
}
.account-container .block-header.main {
  font-size: 40px;
  color: #70257a;
}
.account-container .logout-link a {
  text-align: center;
  padding: 10px;
  color: white;
  background-color: #70257a;
  display: block;
  font: bold 16px founders-grotesk, "Arial", sans-serif;
  text-transform: uppercase;
}
.account-container .form-section {
  background-color: #FFE2A5;
  margin: 20px;
  padding: 20px;
  position: relative;
}
.account-container .form-alert {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.5s ease;
  z-index: 1;
}
.account-container .form-alert.ng-enter, .account-container .form-alert.ng-leave.ng-leave-active {
  opacity: 0;
}
.account-container .form-alert.ng-leave, .account-container .form-alert.ng-enter.ng-enter-active {
  opacity: 1;
}
.account-container .form-alert-success {
  background-color: #E9B646;
}
.account-container label.checkbox {
  color: #70257a;
  font-weight: bold;
  line-height: 15px;
  display: inline-block;
  width: 220px;
  margin: 10px;
}
.account-container label.checkbox small {
  font-weight: normal;
  color: black;
}
.account-container .email-groups label.checkbox {
  line-height: 30px;
}
.account-container .block-info {
  border: 2px solid #FFE2A5;
  text-align: center;
  margin: 20px;
  padding: 20px;
  font: 18px/24px founders-grotesk, "Arial", sans-serif;
}
.account-container .block-info h4 {
  margin: 0;
  font: bold 16px founders-grotesk, "Arial", sans-serif;
  color: #70257a;
  text-transform: uppercase;
}
.account-container .form:last-child {
  margin-bottom: 20px;
}
.account-container .form .form-group button.secondary {
  background-color: #FFE2A5;
  color: #70257a;
}

.simple-subscribe-container .form h3 {
  font-size: 30px;
}
.simple-subscribe-container .form p.terms {
  font-size: 22px;
}
.simple-subscribe-container .form .form-group .btn,
.simple-subscribe-container .form .form-group button {
  font-size: 20px;
}
.simple-subscribe-container .checkboxes-plan .checkbox {
  font-size: 20px;
  line-height: 20px;
  width: 240px;
}
.simple-subscribe-container .checkboxes-plan .checkbox small {
  font-size: 90%;
}
.simple-subscribe-container .subscribe-form .plan .title,
.simple-subscribe-container .subscribe-form .plan .price {
  font-size: 20px;
  line-height: 20px;
}
.simple-subscribe-container .subscribe-form ul li {
  font-size: 22px;
}